import React, { useEffect, useContext } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ShopsContext from '../../context/shops/shopsContext';

function AddOtoshopProductModal({ show, handleClose }) {
  const shopsContext = useContext(ShopsContext);
  const {
    addProduct,
  } = shopsContext;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();



  const handleFormSubmit = async (data) => {
    try {
      await addProduct({
        itemName: data.productName,
        price: parseFloat(data.price),
      });
      reset(); // Reset the form after submission
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleModalClose = () => {
    reset(); // Reset the form when the modal is closed
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Product</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body>

          <Form.Group controlId="formProductName" className="mb-3">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product name"
              {...register('productName', { required: 'Product name is required' })}
              isInvalid={!!errors.productName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.productName?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formProductPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter price"
              {...register('price', {
                required: 'Price is required',
                valueAsNumber: true,
                min: { value: 0, message: 'Price must be greater than or equal to 0' },
              })}
              isInvalid={!!errors.price}
            />
            <Form.Control.Feedback type="invalid">
              {errors.price?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save Product
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddOtoshopProductModal;
