import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import Header from '../../components/Layout/Header';
import Main from '../../components/Layout/Main';
import Select from 'react-select';
import Sidebar from '../../components/Layout/Sidebar';
import ShopsContext from '../../context/shops/shopsContext';
import CustomersContext from '../../context/otogas/customers/customersContext';
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import AddPaymentMethodModal from './addPaymentMethod';
import AddOtoshopProductModal from './addShopItem';
import SetupUsageModal from './setup';
import { validate_consumption } from '../../api/otogas';

const PointOfSale = () => {
    const shopsContext = useContext(ShopsContext);
    const customersContext = useContext(CustomersContext);
    const { listProducts, products, listPaymentMethods, paymentMethods, makeSale } = shopsContext;
    const { fetchVehicles, vehicles } = customersContext;

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [vehicleDetails, setVehicleDetails] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [isValidated, setIsValidated] = useState(false);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });
    const [showAlert, setShowAlert] = useState(false);

    // Modal States
    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
    const [showAddShopProductModal, setShowAddShopProductModal] = useState(false);
    const [showSetupUsageModal, setShowSetupUsageModal] = useState(false);

    const totalPrice = selectedProduct ? selectedProduct.price * quantity : 0;

    useEffect(() => {
        listProducts();
        listPaymentMethods();
    }, []);

    useEffect(() => {
        if (vehicles) {
            const options = vehicles.map(vehicle => ({
                value: vehicle.vehicleCode,
                label: vehicle.vehicleRegistrationNumber,
            }));
            setVehicleOptions(options);
        }
    }, [vehicles]);

    const formattedProducts = products?.map(product => ({
        value: product.itemCode,
        label: product.itemName,
        price: product.price,
    }));

    const formattedPaymentMethods = paymentMethods?.map(method => ({
        value: method.paymentMethod,
        label: method.paymentMethod,
    }));

    const debouncedFetchVehicles = regNo => {
        setTimeout(() => {
            fetchVehicles(1, 10, '', regNo, '', '', true);
        }, 500);
    };

    const handleValidate = async () => {
        if (!selectedProduct || !vehicleDetails) {
            setAlertMessage({ text: 'Please select both a product and a vehicle.', type: 'danger' });
            setShowAlert(true);
            return;
        }

        setIsLoading(true);

        try {
            const response = await validate_consumption(vehicleDetails.value, selectedProduct.value);
            if (response.responseCode === 1) {
                setAlertMessage({ text: 'Validation successful.', type: 'success' });
                setIsValidated(true);
            } else {
                setAlertMessage({ text: response.responseMessage, type: 'danger' });
                setIsValidated(false);
            }
        } catch (error) {
            setAlertMessage({ text: 'Validation failed. Please try again.', type: 'danger' });
        } finally {
            setIsLoading(false);
            setShowAlert(true);
        }
    };

    const handleSell = async () => {
        if (!isValidated) {
            setAlertMessage({ text: 'Please validate the product and vehicle before proceeding.', type: 'danger' });
            setShowAlert(true);
            return;
        }

        if (!paymentMethod) {
            setAlertMessage({ text: 'Please select a payment method.', type: 'danger' });
            setShowAlert(true);
            return;
        }

        const payload = {
            totalAmount: totalPrice,
            paymentMethod,
            vehicleCode: vehicleDetails.value,
            sales: [
                {
                    itemCode: selectedProduct.value,
                    quantity,
                    price: selectedProduct.price,
                },
            ],
        };

        setIsLoading(true);

        try {
            await makeSale(payload);
            setAlertMessage({ text: 'Sale completed successfully.', type: 'success' });

            // Reset form
            setSelectedProduct(null);
            setQuantity(1);
            setVehicleDetails(null);
            setPaymentMethod('');
            setIsValidated(false);
        } catch (error) {
            setAlertMessage({ text: 'Sale failed. Please try again.', type: 'danger' });
        } finally {
            setIsLoading(false);
            setShowAlert(true);
        }
    };

    return (
        <div>
            <Header />
            <Sidebar page="pos" />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={() => setShowAlert(false)}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />

                {/* Card for Modal Triggers */}
                <Card className="p-3 mb-4">
                    <Row className="align-items-center">
                        <Col>
                            <h3 className="mb-0">Otoshop</h3>
                        </Col>
                        <Col className="text-end">
                            <Button
                                variant="primary"
                                className="me-2"
                                onClick={() => setShowAddShopProductModal(true)}
                            >
                                Add Product
                            </Button>
                            <Button
                                variant="secondary"
                                className="me-2"
                                onClick={() => setShowAddPaymentMethodModal(true)}
                            >
                                Add Payment Method
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => setShowSetupUsageModal(true)}
                            >
                                Setup Usage
                            </Button>
                        </Col>
                    </Row>
                </Card>


                {/* Sales Form and Invoice Summary */}
                <Row>
                    <Col md={8}>
                        <Card className="mb-4">
                            <Card.Header as="h5">Sales Form</Card.Header>
                            <Card.Body>
                                <h6>Select Product</h6>
                                <Select
                                    options={formattedProducts}
                                    onChange={option => setSelectedProduct(option)}
                                    placeholder="Select a product"
                                />

                                <h6 className="mt-3">Select Vehicle</h6>
                                <Select
                                    options={vehicleOptions}
                                    onInputChange={debouncedFetchVehicles}
                                    onChange={option => setVehicleDetails(option)}
                                    placeholder="Search and select a vehicle"
                                    isDisabled={!selectedProduct}
                                />

                                <Button
                                    variant="primary"
                                    className="mt-3 w-100"
                                    onClick={handleValidate}
                                    disabled={!selectedProduct || !vehicleDetails || isLoading}
                                >
                                    {isLoading ? <Spinner animation="border" size="sm" /> : 'Validate'}
                                </Button>

                                <h6 className="mt-3">Select Payment Method</h6>
                                <Select
                                    options={formattedPaymentMethods}
                                    onChange={option => setPaymentMethod(option?.value || '')}
                                    placeholder="Select payment method"
                                    isDisabled={!isValidated}
                                />

                                {isValidated && (
                                    <>
                                        <h6 className="mt-3">Quantity</h6>
                                        <div className="d-flex align-items-center">
                                            <Button
                                                variant="secondary"
                                                onClick={() => setQuantity(Math.max(1, quantity - 1))}
                                            >
                                                -
                                            </Button>
                                            <span className="mx-3">{quantity}</span>
                                            <Button
                                                variant="secondary"
                                                onClick={() => setQuantity(quantity + 1)}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="mb-4">
                            <Card.Header as="h5">Invoice Summary</Card.Header>
                            <Card.Body>
                                <p>
                                    <strong>Vehicle:</strong> {vehicleDetails?.label || 'N/A'}
                                </p>
                                <p>
                                    <strong>Payment Method:</strong> {paymentMethod || 'N/A'}
                                </p>
                                <hr />
                                <p>
                                    <strong>Product:</strong> {selectedProduct?.label || 'N/A'}
                                </p>
                                <p>
                                    <strong>Quantity:</strong> {quantity}
                                </p>
                                <p>
                                    <strong>Total Price:</strong> <span className="text-success">KSH {totalPrice}</span>
                                </p>
                                <Button
                                    variant="success"
                                    className="mt-3 w-100"
                                    onClick={handleSell}
                                    disabled={!isValidated || !paymentMethod}
                                >
                                    {isLoading ? <Spinner animation="border" size="sm" /> : 'Sell'}
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Modals */}
                <AddOtoshopProductModal
                    show={showAddShopProductModal}
                    handleClose={() => setShowAddShopProductModal(false)}
                />
                <AddPaymentMethodModal
                    show={showAddPaymentMethodModal}
                    handleClose={() => setShowAddPaymentMethodModal(false)}
                />
                <SetupUsageModal
                    show={showSetupUsageModal}
                    handleClose={() => setShowSetupUsageModal(false)}
                />
            </Main>
        </div>
    );
};

export default PointOfSale;
