export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const ORDER_ERROR = "ORDER_ERROR";
export const GET_ORDERS = "GET_ORDERS";
export const CLEAR_ORDERS = "CLEAR_ORDERS";
export const ADD_ORDER = "ADD_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const FILTER_ORDERS = "FILTER_ORDERS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER" 
export const USER_ERROR = "USER_ERROR";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const FILTER_USERS = "FILTER_USERS";
export const CLEAR_USERS = "CLEAR_USERS";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE"
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER";
export const CLEAR_CURRENT_CUSTOMER = "CLEAR_CURRENT_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const FILTER_CUSTOMERS = "FILTER_CUSTOMERS";
export const LIST_CUSTOMERS = "LIST_CUSTOMERS";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_AGENT_ORDERS = "GET_AGENT_ORDERS";
export const GET_AGENT_ORDERS_ERROR = "GET_AGENT_ORDERS_ERROR";
export const GET_DRIVER_ORDERS = "GET_DRIVER_ORDERS";
export const DRIVER_ORDERS_ERROR = "GET_DRIVER_ORDERS_ERROR";
export const ADD_CUSTOMER_LOCATION = "ADD_CUSTOMER_LOCATION";
export const AGENT_ORDERS_ERROR = "AGENT_ORDERS_ERROR";
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
// stations
export const GET_STATIONS = "GET_STATIONS";
export const GET_STATION = "GET_STATION";
export const ADD_STATION = "ADD_STATION";
export const DELETE_STATION = "DELETE_STATION";
export const SET_CURRENT_STATION = "SET_CURRENT_STATION";
export const CLEAR_CURRENT_STATION = "CLEAR_CURRENT_STATION";
export const UPDATE_STATION = "UPDATE_STATION";
export const STATION_ERROR = "STATION_ERROR";
export const SEARCH_STATION = "SEARCH_STATION";
export const GET_NOZZLES = "GET_NOZZLES";
export const OTOGAS_ERROR = "OTOGAS_ERROR";

export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const LIST_APPS = "LIST_APPS";
export const LIST_TANKS = "LIST_TANKS";
export const LIST_STATION_TANKS = "LIST_STATION_TANKS";

export  const LIST_USER_ROLES = "LIST_USER_ROLES";
export const LIST_ROLES = "LIST_ROLES";
export const ASSIGN_ROLES = "ASSIGN_ROLES";
export const REMOVE_ROLES = "REMOVE_ROLES";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const GET_USER_DETAILS_FROM_TOKEN = "GET_USER_DETAILS_FROM_TOKEN";

export const GET_STATION_DISPENSERS = "GET_STATION_DISPENSERS";
export const GET_DISPENSER_NOZZLES = "GET_DISPENSER_NOZZLES";
export const LIST_VEHICLES = "LIST_VEHICLES";
export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const LIST_TILLS = "LIST_TILLS";
export const LIST_VARIANCES = "LIST_VARIANCES";
export const LIST_SALES = "LIST_SALES";
export const LIST_APP_USERS = "LIST_APP_USERS";
export const LIST_PAYMENT_TYPES = "LIST_PAYMENT_TYPES";
export const LIST_APP_PAYMENT_TYPES = "LIST_APP_PAYMENT_TYPES";
export const LIST_TOPUP_PAYMENT_TYPES = "LIST_TOPUP_PAYMENT_TYPES";
export const LIST_CUSTOMER_WALLETS = "LIST_CUSTOMER_WALLETS";
export const SET_VEHICLE_SUMMARY = "SET_VEHICLE_SUMMARY";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const STATION_ASSIGNMENTS = "STATION_ASSIGNMENTS";
export const CUSTOMER_WARNING = "CUSTOMER_WARNING";
export const STATION_WARNING = "STATION_WARNING";
export const AUTH_WARNING = "AUTH_WARNING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const USER_WARNING = "USER_WARNING";
export const USER_SUCCESS = "USER_SUCCESS";
export const LIST_TANK_SIZES = "LIST_TANK_SIZES";
export const LIST_REPORTS = "LIST_REPORTS"
export const REPORT_RECIPIENTS = "REPORT_RECIPIENTS"
export const GET_MPESA_PAYMENTS = "GET_MPESA_PAYMENTS"
export const LIST_SALE_PAYMENTS = "LIST_SALE_PAYMENTS"
export const SET_VEHICLE_FUELING_EVENTS = "SET_VEHICLE_FUELING_EVENTS"
export const LIST_CUSTOMER_VEHICLES = "LIST_CUSTOMER_VEHICLES"
export const MPESA_CODE_USAGE = "MPESA_CODE_USAGE"
export const GET_TOTALIZER_READINGS = "GET_TOTALIZER_READINGS"
export const SMS_SENDER_NAMES = "SMS_SENDER_NAMES"
export const LIST_ROLE_PERMISSIONS = "LIST_ROLE_PERMISSIONS"
export const LIST_ROLE_USERS = "LIST_ROLE_USERS"
export const LIST_MESSAGES = "LIST_MESSAGES"
export const DOWNLOAD_MESSAGES = "DOWNLOAD_MESSAGES"

export const LIST_SHOP_PRODUCTS = "LIST_SHOP_PRODUCTS";
export const ADD_SHOP_ITEM = "ADD_SHOP_ITEM";
export const UPDATE_SHOP_ITEM = "UPDATE_SHOP_ITEM";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const MAKE_SALE = "MAKE_SALE";
export const LIST_SHOP_SALES = "LIST_SHOP_SALES";
export const LIST_PAYMENT_METHODS = "LIST_PAYMENT_METHODS";
export const SHOPS_ERROR = "SHOPS_ERROR";
export const SHOPS_WARNING = "SHOPS_WARNING";
export const LIST_SHOP_SETUPS = "LIST_SHOP_SETUPS";
export const SETUP_SHOP_ITEM = "SETUP_SHOP_ITEM";

