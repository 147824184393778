import React, { useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import ShopsContext from '../../context/shops/shopsContext';

function SetupUsageModal({ show, handleClose }) {
  const shopsContext = useContext(ShopsContext);
  const { setupShopItem, listProducts, products } = shopsContext;
  const { control, handleSubmit,register, formState: { errors }, reset } = useForm();

  useEffect(() => {
    // Fetch products when the modal opens
    if (show) {
    //   listProducts();
    }
  }, [show, listProducts]);

  const handleFormSubmit = async (data) => {
    try {
      await setupShopItem({
        itemCode: data.itemCode.value, // Get the value from react-select
        duration: parseInt(data.duration, 10),
        litresConsumed: parseFloat(data.litresConsumed),
      });
      reset(); // Reset the form after successful submission
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Error adding item usage:', error);
    }
  };

  const handleModalClose = () => {
    reset(); // Reset the form when the modal is closed
    handleClose();
  };

  // Prepare options for react-select
  const productOptions = products.map((product) => ({
    value: product.itemCode,
    label: `${product.itemName} (${product.itemCode || 'N/A'})`,
  }));

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Item Usage</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formItemCode" className="mb-3">
            <Form.Label>Item Code</Form.Label>
            <Controller
              name="itemCode"
              control={control}
              rules={{ required: 'Item code is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={productOptions}
                  placeholder="Select item code"
                />
              )}
            />
            {errors.itemCode && (
              <div className="invalid-feedback d-block">
                {errors.itemCode.message}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="formDuration" className="mb-3">
            <Form.Label>Duration (days)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter duration in days"
              {...register('duration', {
                required: 'Duration is required',
                valueAsNumber: true,
                min: { value: 1, message: 'Duration must be at least 1 day' },
              })}
              isInvalid={!!errors.duration}
            />
            <Form.Control.Feedback type="invalid">
              {errors.duration?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formLitresConsumed" className="mb-3">
            <Form.Label>Litres Consumed</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter litres consumed"
              step="0.01"
              {...register('litresConsumed', {
                required: 'Litres consumed is required',
                valueAsNumber: true,
                min: { value: 0, message: 'Litres consumed must be a positive value' },
              })}
              isInvalid={!!errors.litresConsumed}
            />
            <Form.Control.Feedback type="invalid">
              {errors.litresConsumed?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save Item Usage
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default SetupUsageModal;
