import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import ShopsContext from "../../context/shops/shopsContext";
import Pagination from "../../components/Layout/Pagination";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import { Button, Spinner, Card } from "react-bootstrap";

const ListPosSales = () => {
    const shopsContext = useContext(ShopsContext);
    const { listSales, sales, salesCount, products, listProducts, notification, clear_notification } = shopsContext;
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });
    const [currentPage, setCurrentPage] = useState(1)

    const totalPages = Math.ceil(salesCount / 10);

    // State for filters and loading
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 10,
        DateCreated: new Date().toISOString().split('T')[0],
        ItemCode: "",
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [filters.pageNumber, filters.pageSize, filters.DateCreated, filters.ItemCode]);

    useEffect(() => {
        setLoading(true);
        listProducts()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, []);

    const fetchData = () => {
        setLoading(true);
        listSales(filters.pageNumber, filters.pageSize, filters.DateCreated, filters.ItemCode)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleConfirm = () => {
        clear_notification();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleItemCodeChange = (selectedOption) => {
        setFilters({ ...filters, ItemCode: selectedOption ? selectedOption.value : "" });
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        fetchData();
    };

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="List Customer" />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">List Sales</h5>

                        {/* Filters */}
                        <Card className="p-3 mb-4">
    <h5 className="mb-3">Filter Options</h5>
    <form onSubmit={handleFilterSubmit} className="mb-3">
        <div className="row align-items-end">
            {/* Date Filter */}
            <div className="col-md-4">
                <label htmlFor="DateCreated" className="form-label">
                    Date Created
                </label>
                <input
                    type="date"
                    name="DateCreated"
                    id="DateCreated"
                    value={filters.DateCreated}
                    onChange={handleInputChange}
                    className="form-control"
                />
            </div>

            {/* Product Filter */}
            <div className="col-md-4">
                <label htmlFor="ItemCode" className="form-label">
                    Product
                </label>
                <Select
                    id="ItemCode"
                    name="ItemCode"
                    value={
                        filters.ItemCode
                            ? {
                                  value: filters.ItemCode,
                                  label: products.find(
                                      (product) => product.itemCode === filters.ItemCode
                                  )?.itemName || "",
                              }
                            : null
                    }
                    onChange={handleItemCodeChange}
                    options={products.map((product) => ({
                        value: product.itemCode,
                        label: product.itemName,
                    }))}
                    placeholder="Select Product"
                    isClearable
                />
            </div>

            {/* Page Size Filter */}
            <div className="col-md-4">
                <label htmlFor="pageSize" className="form-label">
                    Page Size
                </label>
                <select
                    name="pageSize"
                    id="pageSize"
                    value={filters.pageSize}
                    onChange={handleInputChange}
                    className="form-control"
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select>
            </div>
        </div>

        {/* Submit Button */}
        <div className="mt-3 text-end">
            <Button type="submit" variant="primary">
                Apply Filters
            </Button>
        </div>
    </form>
</Card>


                        {/* Loader */}
                        {loading ? (
                            <div className="text-center my-3">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        ) : (
                            <>
                                {/* Table */}
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Vehicle Registration</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Payment Method</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sales.length > 0 ? (
                                            sales.map((sale, index) => (
                                                <tr key={index}>
                                                    <td>{sale.vehicleRegistrationNumber}</td>
                                                    <td>{sale.itemName}</td>
                                                    <td>{sale.quantity}</td>
                                                    <td>{sale.price}</td>
                                                    <td>{sale.paymentMethod}</td>
                                                    <td>{sale.totalAmount}</td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-custom-actions dropdown-toggle"
                                                                type="button"
                                                                id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                Actions
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    No Records found.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                {/* Pagination */}
                                {totalPages > 1 && (
                                    <Pagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                )}
                            </>





                        )}
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default ListPosSales;
