import React, { useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ShopsContext from '../../context/shops/shopsContext';

function AddPaymentMethodModal({ show, handleClose }) {
  const shopsContext = useContext(ShopsContext);
  const { addPaymentType } = shopsContext;
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleFormSubmit = async (data) => {
    try {
      await addPaymentType({ paymentMethod: data.paymentMethod });
      reset(); // Reset the form after successful submission
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Error adding payment method:', error);
    }
  };

  const handleModalClose = () => {
    reset(); // Reset the form when the modal is closed
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Payment Method</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body>
          <Form.Group controlId="formPaymentMethod" className="mb-3">
            <Form.Label>Payment Method</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter payment method"
              {...register('paymentMethod', { required: 'Payment method is required' })}
              isInvalid={!!errors.paymentMethod}
            />
            <Form.Control.Feedback type="invalid">
              {errors.paymentMethod?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save Payment Method
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddPaymentMethodModal;
